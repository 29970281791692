import {
  Fragment,
  type ReactNode,
  createContext,
  useContext,
  useState,
} from 'react';

type ModalContextType = {
  modals: JSX.Element[];
  pushModal: (modal: JSX.Element) => void;
  popModal: () => void;
};

const defaultModalContext: ModalContextType = {
  modals: [],
  pushModal: () => {},
  popModal: () => {},
};

const ModalContext = createContext<ModalContextType>(defaultModalContext);

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [modals, setModals] = useState<JSX.Element[]>([]);

  const pushModal = (modal: JSX.Element) =>
    setModals((modals) => [modal, ...modals]);
  const popModal = () => setModals((modals) => modals.slice(1));

  return (
    <ModalContext.Provider value={{ modals, pushModal, popModal }}>
      {modals.map((Modal) => (
        <Fragment key={Modal.key}>{Modal}</Fragment>
      ))}

      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
