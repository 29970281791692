'use client';
import type { PlatformApiEndpoints } from '@carbonfact/shared/src/types/platform';
import { debounce } from 'lodash';
import {
  type AbstractIntlMessages,
  type IntlError,
  IntlErrorCode,
  NextIntlClientProvider,
} from 'next-intl';
import { useCallback, useRef } from 'react';

import { useFetchWithAuth } from './SWRContext';

type I18NProviderProps = {
  children: React.ReactNode;
  messages: AbstractIntlMessages;
  locale: string;
};

const MAX_MISSING_KEYS = 100;
export const I18NProvider = ({
  children,
  messages,
  locale,
}: I18NProviderProps) => {
  const fetchWithAuth = useFetchWithAuth();

  const missingTranslationKeysRef = useRef<string[]>([]);
  const sendMissingKeys = useCallback(
    debounce(async () => {
      const keys = missingTranslationKeysRef.current;
      if (keys.length > 0) {
        (await fetchWithAuth('/translations/report', {
          method: 'POST',
          body: JSON.stringify({ keys: keys.slice(0, MAX_MISSING_KEYS) }),
        })) as PlatformApiEndpoints['/translations/report']['request'];
        missingTranslationKeysRef.current = [];
      }
    }, 5000),
    [],
  );
  const handleExceptionReport = useCallback(
    (key: string) => {
      missingTranslationKeysRef.current.push(key);
      void sendMissingKeys();
    },
    [sendMissingKeys],
  );

  const handleError = useCallback(
    (error: IntlError) => {
      if (error.code === IntlErrorCode.MISSING_MESSAGE) {
        // Get only the key from the error message
        const key = error.message.replace('MISSING_MESSAGE: ', '');
        handleExceptionReport(key);
        if (
          process.env.NODE_ENV === 'development' &&
          process.env.NEXT_PUBLIC_I18N_DEBUG === 'true'
        ) {
          console.warn(error);
        }
      }
    },
    [handleExceptionReport],
  );
  return (
    <NextIntlClientProvider
      onError={handleError}
      locale={locale}
      messages={messages}
    >
      {children}
    </NextIntlClientProvider>
  );
};
